import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/layout";
import Quote from "../components/quotes";
// import Gallery from "react-grid-gallery";
import Gallery from "react-photo-gallery";
import ImageGallery from "react-image-gallery";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

export interface ICateringProps {}

export function Catering(props: ICateringProps) {
  // Za namene ImageGallery ki uporablja original namesto src
  // let IMAGES = [
  //   {
  //     original:
  //       "https://res.cloudinary.com/dxtfvcupn/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1624840358/spiric/core/20210523_132508_imjd8i.jpg",
  //     thumbnail:
  //       "https://res.cloudinary.com/dxtfvcupn/image/upload/c_thumb,w_400,h_400,g_face,e_art:hokusai/v1624840358/spiric/core/20210523_132508_imjd8i.jpg",
  //   },
  //   {
  //     original:
  //       "https://res.cloudinary.com/dxtfvcupn/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1624840357/spiric/core/20210523_141218_er48f2.jpg",
  //     thumbnail:
  //       "https://res.cloudinary.com/dxtfvcupn/image/upload/c_thumb,w_400,h_400,e_art:hokusai,g_face/v1624840357/spiric/core/20210523_141218_er48f2.jpg",
  //   },

  //   {
  //     original:
  //       "https://res.cloudinary.com/dxtfvcupn/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1624840358/spiric/core/20210523_135529_ugkoi2.jpg",
  //     thumbnail:
  //       "https://res.cloudinary.com/dxtfvcupn/image/upload/c_thumb,w_400,h_400,g_face/v1624840358/spiric/core/20210523_135529_ugkoi2.jpg",
  //   },
  // ];

  // const photos = [
  //   {
  //     src: "https://res.cloudinary.com/dxtfvcupn/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1624840358/spiric/core/mosaic/najajon414-min_llq8bm.jpg",
  //     width: 4,
  //     height: 3,
  //   },
  //   {
  //     src: "https://res.cloudinary.com/dxtfvcupn/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1624840357/spiric/core/mosaic/najajon441-min_v0ts56",
  //     width: 4,
  //     height: 3,
  //   },
  //   {
  //     src: "https://res.cloudinary.com/dxtfvcupn/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1624840358/spiric/core/mosaic/najajon258-min_g3x2rw",
  //     width: 2,
  //     height: 1,
  //   },
  // ];

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Catering | Gostilna Spirić</title>
        <link rel="canonical" href="https://www.gostilna-spiric.si/catering" />
        <meta
          name="description"
          content="Nudimo catering po celotni Sloveniji"
        />
      </Helmet>

      <article className="container flex flex-col mx-auto mt-8 sm:px-4 gap-9">
        <section className="overflow-hidden bg-gray-100 rounded lg:grid lg:grid-cols-2">
          <div className="p-6 text-center sm:p-16 ">
            <div className="content-title">Catering</div>
            <div className="flex flex-col gap-5 leading-relaxed text-justify">
              <Quote quote="Želimo si, da bi bili vaši dogodki kulinarično bogati z nami!" />

              <div className="block lg:hidden">
                <StaticImage
                  className="block lg:hidden"
                  src="../images/najajon/compressed/najajon414-min.jpg"
                  alt="Catering Spirić - Solate"
                />
              </div>

              <div className="">
                Nudimo storitve cateringa za vsako priložnost –{" "}
                <b>
                  poroke, rojstne dneve, koncerte, obletnice in mnogo drugega.
                </b>{" "}
                Vaš posebni dogodek lahko po dogovoru pripravimo v zasebnosti
                vašega doma, poslovnih prostorih ali vaši posebni izbrani
                lokaciji. V svoji ponudbi imamo različne možnosti kulinarične in
                dekorativne ponudbe, ki omogočajo, da svojim naročnikom
                pričaramo edinstven in nepozaben dogodek, ki se ga z veseljem
                spominjajo. V sklopu storitve cateringa poskrbimo za celotno{" "}
                <b>kulinarično izkušnjo, pogrinjke in namizno dekoracijo</b>,
                hkrati zraven nudimo tudi{" "}
                <b>možnosti najema miz, stolov in šotorov</b>.
              </div>

              <div className="block lg:hidden">
                <StaticImage
                  className="block lg:hidden"
                  src="../images/najajon/compressed/najajon441-min.jpg"
                  alt="Catering Spirić - Mini burgerji"
                />
              </div>
              <div className="">
                <i>
                  <b>Prepustite se našemu vodstvu in dovolite</b>
                </i>
                , da vam pripravimo nepozabno kulinarično izkušnjo, ob kateri se
                boste lahko brezskrbno posvetili svojim gostom in ostalim
                vidikom vašega posebnega dogodka.
              </div>

              <div className="block lg:hidden">
                <StaticImage
                  className="block lg:hidden"
                  src="../images/najajon/compressed/najajon258-min.jpg"
                  alt="Catering Spirić - Kanapeji"
                />
              </div>
              <div className="flex flex-row items-center justify-center">
                <Link
                  className="p-4 text-yellow-600 bg-white border-2 border-yellow-600 rounded-sm shadow font-raleway hover:bg-yellow-600 hover:text-white"
                  to="/kontakt"
                >
                  Kontaktirajte nas
                </Link>
              </div>
            </div>

            {/* <div className="text-left">
              Nudimo storitve s področja Cateringa, da bi bili vaši dogodki
              kulinarično bogati z nami. Catering opravljamo
              <b> na vseh lokacijah </b>
              in terminih po predhodnem dogovoru.
            </div> */}
          </div>

          <div className="hidden max-h-[300px] lg:block">
            <div className="grid grid-cols-6 grid-rows-2 gap-0.5 ">
              <StaticImage
                className="object-cover col-span-3 "
                src="../images/najajon/compressed/najajon414-min.jpg"
                alt="Catering Spirić - Solate"
              />

              <StaticImage
                className="object-cover col-span-3 "
                src="../images/najajon/compressed/najajon441-min.jpg"
                alt="Catering Spirić - Mini burgerji"
              />
              <StaticImage
                className="object-cover col-span-6 "
                src="../images/najajon/compressed/najajon258-min.jpg"
                alt="Catering Spirić - Kanapeji"
              />
              <StaticImage
                className="object-cover col-span-2 "
                src="../images/najajon/compressed/najajon413-min.jpg"
                alt="Catering Spirić - Kanapeji"
              />
              <StaticImage
                className="object-cover col-span-4 "
                src="../images/najajon/compressed/najajon252-min.jpg"
                alt="Catering Spirić - Kanapeji"
              />
            </div>
          </div>

          {/* <div className="grid bg-gray-200 place-items-center">Slike</div> */}
          {/* <div className="w-full h-full">
            <Gallery photos={photos} />
          </div> */}
        </section>

        <section className="grid grid-rows-1 overflow-hidden bg-gray-100 rounded lg:grid-cols-2 md:grid-rows-1">
          <div className="hidden lg:block">
            <div className="grid grid-cols-6 grid-rows-2 gap-0.5 ">
              <StaticImage
                className="object-cover col-span-3 "
                src="../images/najem/sotor1.jpg"
                alt="Spirić - šotori"
              />

              <StaticImage
                className="object-cover col-span-3 "
                src="../images/najem/sotor_kozel.jpg"
                alt="Spirić - šotori"
              />
              <StaticImage
                className="object-cover col-span-6 "
                src="../images/najem/sotor3.jpg"
                alt="Spirić - šotori"
              />
            </div>
          </div>
          <div className="grid p-6 text-center sm:p-16 place-content-center">
            <div className="content-title">Najem</div>
            <div className="flex flex-col gap-5">
              <div className="leading-relaxed text-left">
                Poleg storitev Cateringa, obsega naš asortiman storitev tudi
                ugodni najem šotorov, miz in stolov za raznovrstne dogodke.
              </div>
              <div className="flex flex-col gap-5 lg:hidden">
                <StaticImage
                  src="../images/najem/sotor1.jpg"
                  alt="Spirić - šotori"
                />

                <StaticImage
                  src="../images/najem/sotor3.jpg"
                  alt="Spirić - šotori"
                />
                <StaticImage
                  src="../images/najem/sotor_kozel.jpg"
                  alt="Spirić - šotori"
                />
              </div>
              <div className="flex flex-row items-center justify-center">
                <Link
                  className="p-4 text-yellow-600 bg-white border-2 border-yellow-600 rounded-sm shadow font-raleway hover:bg-yellow-600 hover:text-white"
                  to="/kontakt"
                >
                  Kontaktirajte nas
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="grid bg-gray-200 md:order-first place-items-center"> */}
          {/* <div> */}
          {/* https://www.npmjs.com/package/react-image-gallery */}
          {/* <ImageGallery
              items={IMAGES}
              autoPlay={true}
              showPlayButton={false}
            /> */}
          {/* </div> */}
        </section>
      </article>
    </React.Fragment>
  );
}

export default Catering;
